import { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Center, Card, Input, Badge, useColorMode,
    Flex, IconButton, Button, Table, TableContainer, Thead, Tr, Th, Tbody, Td, TableCaption,    
    CardBody, Menu, MenuButton, MenuList, MenuItem, HStack, Spacer, Drawer,
    DrawerOverlay, DrawerContent, DrawerHeader, Stack, FormLabel, Textarea, DrawerFooter, Text,
    DrawerCloseButton, DrawerBody, useDisclosure, Modal, ModalBody, ModalContent, ModalCloseButton,
    ModalHeader, ModalOverlay, ModalFooter, FormControl, Select, useToast ,Tabs, TabList, Tab, useColorModeValue} from "@chakra-ui/react";
import { ChevronDownIcon, RepeatIcon, AddIcon } from "@chakra-ui/icons";

import { 
    getHistories,
    getDownloadFile
} from "../Api";

import Pagination from "../components/PaginationComponent";
import { usePageColor } from "../contexts/ColorContext";

const ChatHistoryPage = ({checkExpireTime}) => {

    const colors = usePageColor('rag');
    const { colorMode } = useColorMode();

    const [currentUsage, setCurrentUsage] = useState(0);
   
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
    const [selectedPage, setSelectedPage] = useState(1);
    const [inputKeyword, setInputKeyword] = useState('');
    const [allHistoriesData, setAllHistoriesData] = useState({result: []});

    const displayLimit_list = [10, 20, 30, 50];
    const [displayLimit, setDisplayLimit] = useState(10);

    const toast = useToast();
    const navigate = useNavigate();
    const inputRef = useRef(null);

    // Drawer 관련 상태 시작
    const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
    const [drawerValue, setDrawerValue] = useState({});
    // Drawer 관련 상태 끝

    // Modal 관련 상태 시작
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
    const [modalValue, setModalValue] = useState({categoryId: '1', categoryName: '복리후생',fileOutline: '', fileName: '', file: {name: "파일 선택"}}); // default modal value 상수로 처리하기

    const fetchData = async (displayLimitRows) => {
        if (displayLimitRows === undefined || displayLimitRows === null || displayLimitRows === 0) displayLimitRows = 10;
        await checkExpireTime();
        setAllHistoriesData({result: []});
        setSelectedPage(1);

        try {
          const data = await getHistories(inputKeyword, displayLimitRows);

          if (Array.isArray(data.result)) {
            setDisplayLimit(displayLimitRows)
            setAllHistoriesData(data);
            return;
          } else if (data.result === 'Token Expired') {
            toast({
              title: '토큰이 만료되었습니다.',
              description: '토큰이 만료되었습니다. 재발급 받으세요',
              status: 'info',
              isClosable: true,
              position: 'top',
            });
            return;
          } else {
            toast({
              title: 'Fetch Failed',
              description: 'RAG 정보 가져오기 실패',
              status: 'error',
              isClosable: true,
              position: 'top',
            });
            return;
          }
        } catch (error) {
          toast({
            title: 'Failed',
            description: 'RAG 정보 가져오기 실패',
            status: 'error',
            isClosable: true,
            position: 'top',
        });
        return;
        }
    };

    useEffect(() => {
        setSelectedPage(1);    
        setAllHistoriesData({result: []});
        fetchData();
    }, [inputKeyword]);
    

    const handleDownloadFile = useCallback(async() => {
        await checkExpireTime();
        const fileId = drawerValue.file_id
        try {
            await getDownloadFile(fileId)
        } catch {
            toast({
                title: 'Failed',
                description: '파일 다운로드 실패',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        }
        }, [drawerValue]);

    const uploadedTime = (time) => {
        const yearMonthDay = time.split('T')[0];
        const hourMinuteSeconds = time.split('T')[1].split('.')[0];
        return yearMonthDay + " " + hourMinuteSeconds;
    }

    useEffect(()=>{
        if (currentUsage === 0) {
            setModalValue({categoryId: '1', categoryName: '복리후생',fileOutline: '', fileName: '', file: {name: "파일 선택"}})
        } else if (currentUsage === 1) {
            setModalValue({categoryId: '1', categoryName: '테스트',fileOutline: '', fileName: '', file: {name: "파일 선택"}})
        }
    }, [currentUsage, onModalClose])

    return (
        <Container bg={colors.ContainerMainBg} maxW='' size='container.3xl' h='95vh'>
            <TableContainer minH='90vh'>
                <Center mt='10'>
                <Flex direction='column' minWidth='70%'>
                <Tabs variant='unstyled' mb ='3'>
                </Tabs>
                    <Card borderRadius='13px' bg={colors.CardBg}>
                        <CardBody>
                        <Flex>
                        <Menu>
                            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} w="300" borderRadius='13px'>
                                최근 조회 건수 : {displayLimit}
                            </MenuButton>
                            <MenuList>
                                {displayLimit_list.map((displayLimit)=>(
                                    <MenuItem
                                        key = {displayLimit}
                                        onClick={()=>{
                                            fetchData(displayLimit);          
                                        }}
                                        >
                                        {displayLimit}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Menu>
                        <Spacer />
                        <HStack>
                        <Text>질문 검색: </Text> <Input
                            placeholder='Search for...'
                            borderRadius='13px'
                            ref = {inputRef}
                            onChange={(e)=>{
                                setInputKeyword(e.target.value);
                            }}
                            >                
                        </Input>         
                        <IconButton
                            icon={<RepeatIcon/>}
                            borderRadius='13px'
                            bg={colors.CardIconBg}
                            onClick={()=>{
                                inputRef.current.value = '';
                                setSelectedCategoryIndex(0);
                                setInputKeyword("");  
                                setSelectedPage(1);    
                                fetchData();          
                            }}
                        />   
                        </HStack>                        
                        </Flex>                               
                        </CardBody>
                    </Card>

                    <Table variant='simple' mt='10'>
                        <TableCaption>2024 © Didim365. All Rights Reserved.</TableCaption>
                        <Thead>
                            <Tr>
                                <Th isNumeric>No.</Th>
                                <Th>질문</Th>
                                <Th>검색 사례 건수</Th>
                                <Th>판 단</Th>
                                <Th>사용자</Th>
                                <Th>질문 일시</Th>
                            </Tr>
                        </Thead>
                        {allHistoriesData.result.slice((selectedPage-1)*10, selectedPage*10).map((historyData, index)=>(
                        <Tbody
                            key={index}
                            _hover={{
                                bg: '#f7f5ed',
                                cursor: 'pointer'
                            }}
                            onClick={()=>{
                                onDrawerOpen();
                                setDrawerValue({
                                    "id": (selectedPage-1)*10+index+1,
                                    "chat_uuid":historyData.chat_uuid, 
                                    "user_name": historyData.user_name,
                                    "question": historyData.question,
                                    "top_k": historyData.top_k,
                                    "claimant_argument": historyData.claimant_argument,
                                    "judgment": historyData.judgment,
                                    "related_laws": historyData.related_laws,
                                    "time": historyData.time,
                                    "date": historyData.date,
                                    "hhmmss": historyData.hhmmss,
                                    "file_id": historyData.file_id,
                                    "file_name": historyData.file_name,
                                });}                                
                            }>
                            <Tr>
                                <Td>{(selectedPage-1)*10+index+1}</Td>
                                <Td maxWidth="450px" isTruncated>{historyData.question}</Td>
                                <Td textAlign="right">{historyData.top_k}</Td>
                                <Td maxWidth="600px" isTruncated>{historyData.judgment}</Td>
                                <Td >{historyData.user_name}</Td>
                                <Td >{uploadedTime(historyData.time)}</Td>
                            </Tr>
                        </Tbody>
                        ))}
                    </Table>
                    <Center mt='3'>
                    <Pagination selectedPage={selectedPage} setSelectedPage={setSelectedPage} allRowsNumber={allHistoriesData.result.length} rowsNumberPerPage={10}/>
                    </Center>                    
                </Flex>             
                </Center>                
            </TableContainer>
            <Drawer onClose={onDrawerClose} isOpen={isDrawerOpen} drawerValue={drawerValue} size='xl'>
                <DrawerOverlay/>
                <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth='1px'>
                    채팅 히스토리 정보
                </DrawerHeader>

                <DrawerBody>
                    <Stack spacing='24px'>
                    <Box textAlign="center" fontWeight="bold">
                        <Text
                            as="span"
                            borderBottom="1px solid"
                            width="100%"
                            display="inline-block"
                            position="relative"
                            pb="2"
                        >
                            사용자의 질문
                        </Text>
                        <Input
                            borderRadius='13px'
                            id='question'
                            value={drawerValue.question}                            
                            readOnly={true}
                            bg='#f5f5f5'
                            mt="4"
                        />
                    </Box>
                    <Box textAlign="center" fontWeight="bold">
                        <Text
                            as="span"
                            borderBottom="1px solid"
                            width="100%"
                            display="inline-block"
                            position="relative"
                            pb="2"
                        >
                            유사 사례 검색 결과
                        </Text>
                    </Box>
                    <Box>
                        <FormLabel htmlFor='file_name'>파일 명</FormLabel>
                        <Input
                            borderRadius='13px'
                            id='file_name'
                            value={drawerValue.file_name}                            
                            readOnly={true}
                            bg='#f5f5f5'
                        />
                    </Box>
                                    
                    <Box>
                    <FormLabel htmlFor='claimant_argument'>청구인의 주장</FormLabel>
                        <Textarea 
                            borderRadius='13px'
                            id='claimant_argument'
                            value={drawerValue.claimant_argument}
                            readOnly={true}
                            bg='#f5f5f5'
                            rows={3}
                        />
                    </Box>
                    
                    <Box>
                        <FormLabel htmlFor='judgment'>판 단</FormLabel>
                        <Textarea 
                            borderRadius='13px'
                            id='judgment'
                            value={drawerValue.judgment}
                            readOnly={true}
                            bg='#f5f5f5'
                            rows={12}
                        />
                    </Box>

                    <Box>
                        <FormLabel htmlFor='related_laws'>관련법령 등</FormLabel>
                        <Textarea 
                            borderRadius='13px'
                            id='related_laws'
                            value={drawerValue.related_laws}
                            readOnly={true}
                            bg='#f5f5f5'
                            rows={12}
                        />
                    </Box>

                    </Stack>
                </DrawerBody>
                <DrawerFooter borderTopWidth='1px'>
                    <Button borderRadius='10px' variant='outline' mr={3} onClick={onDrawerClose}>
                    Cancel
                    </Button>
                    <Button colorScheme='green' borderRadius='10px' mr={3} onClick={handleDownloadFile}>Download</Button>
                </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </Container>
    );
}

export default ChatHistoryPage;