import React from 'react';

const SplitText = ({ text = '' }) => {
  // 숫자. 패턴과 날짜 형식을 예외 처리한 마침표 구분
    const splitByLength = (text) => {
        // 숫자. 패턴 또는 날짜 형식(예: 2023.1.10)을 제외한 마침표를 기준으로 문장 분리
        const sentences = text.split(/(?<!\d)\.(?!\d)(?=\s|$)/).map(sentence => sentence.trim());  
        
        let currentParagraph = '';
        const paragraphs = [];

        sentences.forEach((sentence) => {
          if (currentParagraph.length + sentence.length > 300) {  // 문장이 너무 길면 새 문단
            paragraphs.push(currentParagraph.trim() + '.');
            currentParagraph = sentence;
            } else {
            currentParagraph += (currentParagraph ? ' ' : '') + sentence;
            }
        });

        if (currentParagraph.trim()) {
          paragraphs.push(currentParagraph.trim() + '.');
        }
    return paragraphs;
};

const paragraphs = splitByLength(text);
    return paragraphs.map((paragraph, i) => (
        <React.Fragment key={i}>
        {paragraph.split(/\n/).map((line, j) => (
            <React.Fragment key={j}>
            {line.trim()}<br />
            </React.Fragment>
        ))}
          <br /> {/* 문단 간 두 줄 간격 */}
        </React.Fragment>
    ));
};

export default SplitText;
