import Cookies from 'js-cookie';

const getBaseUrl = () => {
    const env = process.env.NODE_ENV
    if(env === 'production') {
        return ''
    } else {
        return 'http://localhost:8000'
    }
}


/////////////// 인증관련 API //////////////////
const getTokens = (type) => {
    if (type === 'refresh') {
        const refreshToken = localStorage.getItem('refresh-token');
        return {'refresh-token': refreshToken};
    } else if (type === 'access') {
        const accessToken = localStorage.getItem('access-token');
        return {'access-token': accessToken};
    }
}

const getRefresh = async () => {
    const refreshToken = getTokens("refresh");

    const apiUrl = `${getBaseUrl()}/api/auth/refresh`;

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${refreshToken['refresh-token']}`
            },
        });
        const data = await response.json();

        localStorage.setItem('access-token', response.headers.get('access-token'));
        localStorage.setItem('refresh-token', response.headers.get('refresh-token'));

        return data;
    } catch (error) {
        throw error;
    }
};

const postSignIn = async (username, password) => {
    const apiUrl = `${getBaseUrl()}/api/auth/sign_in`;

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_name: username,
                user_password: password
            }),
            credentials: 'include'
        });
        const data = await response.json();

        localStorage.setItem('access-token', response.headers.get('access-token'));
        localStorage.setItem('refresh-token', response.headers.get('refresh-token'));
        
        return data;
    } catch (error) {
        throw error;
    }
};
//////////////// 인증관련 API 끝 //////////////////
const getDomains = async () => {
    // const apiUrl = `${config.config.base_url}/api/domains`;
    const apiUrl = `${getBaseUrl()}/api/domains`;

    try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const postRun = async function (inputQuery, topk) {
    
    const apiUrl = `${getBaseUrl()}/api/run`;
    const accessToken = getTokens('access');
    
    const headers = {
        'Content-Type': 'application/json'
    };
    
    if (accessToken['access-token'] !== null) {
        headers['Authorization'] = `Bearer ${accessToken['access-token']}`;
    }

    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            query: inputQuery,
            topk: topk
        }),
        credentials: 'include'
    });
    const data = await response.json();
    return data;
};

const getDownloadFile = async (fileId) => {

    const apiUrl = `${getBaseUrl()}/api/download_file?file_id=${fileId}`;
    
    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            credentials: 'include'
        })
    
        const blob = await response.blob();
        const text = await blob.text(); // Get the text content of the blob
            
        // 헤더를 직접 읽기
        const encodedFileName = response.headers.get('Content-Disposition');
        console.log('Content-Disposition:', encodedFileName); 
        if (!encodedFileName) {
            throw new Error('Content-Disposition header is missing');
        }
    
        const fileName = decodeURIComponent(encodedFileName);
        console.log("fileName", fileName);
    
        if (blob) {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName; // You can use the actual filename if available
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
    
        // Return both the text content and the blob
        return { success: true, content: text, blob: blob };

    } catch (error) {
        console.error('Error downloading the document:', error);
        throw error;
    }
};

/////////////// ChatbotPage 끝 /////////////////////

/////////////// ChatHistoryPage 시작 /////////////////////

/////////////// ChatHistoryPage 끝  /////////////////////

/////////////// RagPage /////////////////////
const getRags = async (activedDomainIndex, inputKeyword) => {
    const accessToken = getTokens("access");
    const apiUrl = `${getBaseUrl()}/api/rag?usage_id=${activedDomainIndex}&keyword=${encodeURIComponent(inputKeyword)}`;

    try {
        const response = await fetch(apiUrl, {
            headers: {
                'Authorization': `Bearer ${accessToken['access-token']}`
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const putRagOneRow = async (drawerValue) => {
    const accessToken = getTokens("access");

    const apiUrl = `${getBaseUrl()}/api/rag`;

    if (drawerValue.file_outline === null) {
        drawerValue.file_outline = '';
    }
    
    try {
        const response = await fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken['access-token']}`
            },
            
            body: JSON.stringify({
                "file_id": drawerValue.file_id,
                "file_name": drawerValue.file_name,
                "doc_outline": drawerValue.file_outline
            }),
        });

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

const deleteRagOneRow = async (drawerValue) => {
    const accessToken = getTokens("access");

    const apiUrl = `${getBaseUrl()}/api/rag?file_id=${drawerValue.file_id}`;

    try {
        const response = await fetch(apiUrl, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken['access-token']}`
            },
        });

        const data = await response.json();
        return data;

    } catch (error) {
        throw error;
    }
};

const postRagOneRow = async (modalValue) => {
    const accessToken = getTokens("access");

    const apiUrl = `${getBaseUrl()}/api/rag`;
    const formData = new FormData();
    formData.append('usage_id', modalValue.categoryId);
    formData.append('file_name', modalValue.fileName);
    formData.append('usage_name', modalValue.categoryName);
    formData.append('file', modalValue.file);
    formData.append('doc_outline', modalValue.fileOutline);

    console.log(formData);


    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken['access-token']}`
            },
            body: formData
        });

        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};
/////////////// RagPage 끝 /////////////////////

/////////////// Summary Page /////////////////
const postMultitaskSummaryDocuments = async(userPrompt, documentGuideQuery, temperature, userFile, isDocumentSummaryTitleFixed) => {
    let fileList = []
    let formData = new FormData();

    if (!isDocumentSummaryTitleFixed && !userPrompt) {
        throw new Error('문서 제목을 입력해 주세요.')
    }

    const apiUrl = `${getBaseUrl()}/api/multitask/create_report`;
    if (isDocumentSummaryTitleFixed) {
        userPrompt = '';
    }
    userFile.map((file)=>{
        fileList.push(file.file);
    })

    for (let i = 0; i < fileList.length; i++) {
        formData.append('files', fileList[i]);
    }
    formData.append('generate_title_with_ai', isDocumentSummaryTitleFixed);
    formData.append('report_title', userPrompt);
    formData.append('documentGuideQuery', documentGuideQuery);
    formData.append('temperature', temperature);

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: formData,
        });
        const data = await response.json();
        return data;
    } catch (error){
        console.log(error)
        throw error;
    }
}

const postMultitaskDownloadReport = async (reportTitle, markdownContent) => {
    const apiUrl = `${getBaseUrl()}/api/multitask/download_report`;
    const headers = {
        'Content-Type': 'application/json'
    };

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            body: JSON.stringify({
                report_title: reportTitle,
                markdown_content: markdownContent,
                file_type: "docx"  
            }),
            headers: headers,
        });
        const blob = await response.blob();
        const text = await blob.text(); // Get the text content of the blob
        
         // 헤더를 직접 읽기
        const encodedFileName = response.headers.get('Content-Disposition');
        console.log('Content-Disposition:', encodedFileName); 
        if (!encodedFileName) {
            throw new Error('Content-Disposition header is missing');
        }

        const fileName = decodeURIComponent(encodedFileName);
        console.log("fileName", fileName);

        if (blob) {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = fileName; // You can use the actual filename if available
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
        // Return both the text content and the blob
        return { success: true, content: text, blob: blob };
    } catch (error) {
        throw error;
    }
}
/////////////// WebSearchPage 끝 /////////////////
const getHistories = async (inputKeyword, displayLimit) => {
    const accessToken = getTokens("access");
    const apiUrl = `${getBaseUrl()}/api/history?question=${encodeURIComponent(inputKeyword)}&displayLimit=${displayLimit}`;
    
    try {
        const response = await fetch(apiUrl, {
            headers: {
                'Authorization': `Bearer ${accessToken['access-token']}`
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

/////////////// Summary Page 끝 /////////////////

export {
    getDomains,
    postRun,
    getRags,
    postRagOneRow,
    putRagOneRow,
    deleteRagOneRow,
    postSignIn,
    getRefresh,
    getDownloadFile,
    postMultitaskSummaryDocuments,
    postMultitaskDownloadReport,
    getHistories
};
